<template>
  <div class="nav">
    <div class="navImg" v-for="item in img" :key="item.url">
      <img @click="Collection" :src="item.url" alt="" />
    </div>
  </div>
</template>

<script>
import { getBanner } from '@/api/api';
import download from '@/composables/download';

export default {
  name: 'Banner',

  setup() {
    const { downloadHandler } = download();

    return {
      downloadHandler,
    };
  },

  data() {
    return {
      img: [],
    };
  },

  created() {
    this.getUserList();
  },

  methods: {
    getUserList() {
      getBanner({
        params: { id: this.$route.query.id },
      }).then((res) => {
        this.img = res.bannerImages;
      });
    },

    Collection() {
      this.downloadHandler(this.$route.query.from);
    },
  },
};
</script>

<style lang="scss" scoped>
.navImg {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
}
</style>
